
.contact{
   >div:nth-child(1){
        display: flex;
        border-bottom: 1px solid rgba(204, 201, 201, 0.87);
        >div:nth-child(1){
            display: flex;
        }
        >div:nth-child(2){
           >div{
              >div{
                  font-weight: 900;
                  font-size: 350%;
              }
           }

           padding: 2%;
           >form{
              >div:nth-child(1){
                 >input{
                     border: none;
                     border-bottom: 1px solid gray;
                     width: 100%
                 }
              }
              >div:nth-child(2){
                  >input{
                     padding-top: 15px;
                     border: none;
                     border-bottom: 1px solid gray;
                     width: 100%
                  }
              }
              >div:nth-child(3){
                  >label{
                     padding-top: 15px;
                     
                  }
              }
              >div:nth-child(4){
                 height: 100%;
                  >textarea{
                     border: 1px solid grey;
                     width: 100%;
                     // margin-bottom: 10%
                  }
              }
              >div:nth-child(5){
               display: flex;
               flex-direction: column;
               align-items: center;
               padding-top: 20px ;
               >Button{
                  object-position: bottom;
                  width: 30%;
                 
               }
           }
           }
           
        }
       
   }
   >div:nth-child(2){
        display: flex;
       align-items: center;
       justify-content: center;
   }

} 


@media (max-width: $table){
}

@media (max-width: $phone){
   .contact{
      >div:nth-child(1){
         >div:nth-child(1){
            display: none;
         }
      }
   }
}