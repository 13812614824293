.menu{
    //background-color: white;
    margin: 3%;
   

    >h1{
        //menu header
        text-align: left;
        color: black;
        font-size: 30px;
        padding-top: 2%;
        padding-left: 4%;

    }

    >ul{
        //menu list
        display: flex;
        flex-direction: row;
        align-items: left;
        padding-left: 1.5%;
       >div{
       // background-color:yellow;
        align-self: flex-start;
           >ul{
               color:black;
               //padding-left: 5px;
               //padding-right: 5px;
               padding-bottom: 0%;
               //padding-top: 15%;
               font-size: large;
               text-align: left;  
           }
       }
    }

    >div{
        >div{
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
           
               >div{
                   border: 1px solid white;
                   box-shadow: 2px 0 25px 0 rgba(106, 152, 204, 0.925);
                   width: 30%;
                   margin-top: 3%;
                    >img{
                        width: 100%;
                        height: 70%;
                    }
                    >div{
                        margin: 2%;
                        margin-left: 6%;
                        margin-right: 6%;
                    }
                    >div:nth-child(2){
                        font-weight: 600;
                        font-size: 140%;
                    }
                    >div:nth-child(3){
    
                    }
                    >div:nth-child(4){
                        float:right;
                        >span{
                            font-weight: 800;
                        }
                        margin-bottom: 4%;
                    }
               }
        }
        
    }
}

@media (max-width: $table){
    .menu{
        >div{
            >div{
                >div{
                    width: 45%
                }
            }
        }
    }
}

@media (max-width: $phone){
    .menu{
        >ul{
            border-bottom: 1px solid black;
           >div:nth-child(1){
                margin-left: -10%;
                display: block;
           }
           >div{
               display: none;
           }
        }
        >div{
            >div{
                >div{
                    width: 100%
                }
            }
        }
    }
}