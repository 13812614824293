.topbar{
    background-color: rgb(88, 65, 47);
    >a{
        color: white;
        font-size: 180%;
    }
    >div:nth-child(1){
        margin-left: 3%;
    }
    >div{
        >ul{
            >li{
                margin-left: 3%;
                margin-right: 3%;
                cursor:pointer;
                margin-top: 2%;
                >a{
                    color: white;
                    text-decoration:none;
                    font-size: 130%;
                }
                >a:hover{
                    color: rgb(253, 170, 103);
                }
            }
        }
        >a:nth-child(2){
            width: 17%;
            >button{
                border-color: rgb(250, 142, 53);
                background-color: rgb(250, 142, 53);
                width: 100%
            }
            :hover{
                border-color: rgb(190, 106, 36);
                background-color: rgb(190, 106, 36);
            }
        }
    }
}
@media (max-width: $table){
    .topbar{
        >div:nth-child(4){
            >a{
                >button{
                    margin-top: 3%;
                }
            }
        }
        
    }
}

@media (max-width: $phone){
    .topbar{
        >a{
            font-size: 150%;
        }
        >div:nth-child(4){
            >a{
                >button{
                    margin-top: 6%;
                }
            }
        }
        
    }
}