.homeStyle{
    background-image: url('../../images/home_back.jpg');
    height: 100vh;
    background-position: 100% 90%; 
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0%;
    padding: 0%;
    overflow: hidden;
    white-space: nowrap;
    >ul{
        margin-top: 8%;
        margin-left: 5%;
        list-style-type: none;
        >li:nth-child(1){
            >img{
                width: 27%;
            }
        }
        >li{
            >div{
                color: white;
                font-size: 230%;
                font-weight: 600;
                margin: -2%;
                margin-top: 1%;
                margin-left: 1%;
                text-shadow: 2px 2px rgb(5, 6, 26);
            }
            >div:nth-child(2){
                margin-top: 2%;
                font-size: 125%;
                font-weight: 300;
                text-shadow: 1px 1px rgb(5, 6, 26);
            }
        }
        >li:nth-child(4){
            margin-top: 3%;
            margin-left: 1%;
            >a{
                >button{
                    width: 18%;
                    margin-right: 1%
                }
            }
            >button{
                width: 18%;
                margin-right: 1%
            }

        }
    }
}

.home_menu_style{
    margin: 4%;
    >h1{
        margin-left: 4%;
    }
    >ul{
        display: flex;
        flex-direction: row;
        column-count: 3;
        align-items: center;
        justify-content: center;
        list-style-type: none;
        >li{
            box-shadow: 
            inset 0 -3em 3em rgba(0,0,0,0.1), 
             0 0  0 2px rgb(255,255,255),
             0.3em 0.3em 1em rgba(0,0,0,0.3);
            margin: 1%;
            >div{
                display: flex;
                align-items: center;
                justify-content: center;
                >img{
                    width: 100%
                }
                >button{
                    align-items: center;
                    width: 70%;
                    margin:5%;
                }
            }
            >h3{
                text-align: center;
                margin: 3%;
            }
            >h5{
                text-align: center;
                margin: 7%;
                font-size: 120%;
                font-weight: 300;
            }
        }
    }
}

.home_about_style{
    margin: 4%;
    >h1{
        margin-left: 4%;
    }
    >div{
        margin: 4%;
        padding: 3%;
        text-align: center;
        box-shadow: 0 0 25px 0 rgba(24, 132, 240, 0.575);
        >img{
            width: 25%;
            margin: 2%
        }
        >div{
            padding: 2%;
            margin-left: 5%;
            margin-right: 5%; 
            font-size: 140%;
            font-weight: 600;
        }
    }
}

.home_hours_style{
    text-align: center;
    margin: 8%;
    >div:nth-child(1){
        font-size: 160%;
        font-weight: 600;
        display: block;
    }
    >div{
        font-size: 160%;
        display: inline;
    }
}

@media (max-width: $table){
    .homeStyle{
        background-position: 100% 100%; 
        >ul{
            text-align: center;
            margin-top: 15%;
            margin-left: 0%;
            list-style-type: none;
            >li:nth-child(1){
                >img{
                    width: 50%;
                }
            }
            >li{
                >div{
                    font-size: 300%;
                    font-weight: 600;
                }
            }
            >li:nth-child(4){
                >a{
                    >button{
                        width: 30%;
                        margin-right: 3%
                    }
                }
                >button{
                    width: 30%;
                    margin-right: 1%
                }
    
            }
        }
    }

}

@media (max-width: $phone){
    .homeStyle{
        background-position: 80% 100%; 
        >ul{
            margin-top: 35%;
            margin-left: -8%;
            >li:nth-child(1){
                >img{
                    width: 75%;
                }
            }
            >li{
                >div{
                    font-size: 200%;
                }
            }
            >li:nth-child(4){
                margin-top: 70%;
                >a{
                    display: block;
                    margin: 2%;
                    width: 90%;
                    >button{
                        width: 100%;
                        margin-right: 0%
                    }
                }
                >button{
                    display: block;
                    margin: 2%;
                    width: 90%;
                    margin-right: 0%;
                    margin-top: 5%;
                    background-color: red;
                    border-color: red;
                }
    
            }
        }
    }

    .home_menu_style{
        margin: 0%;
        >h1{
            margin-left: 4%;
            margin: 5%;
            text-align: center;
            font-weight: 700;
        }
        >ul{
            flex-wrap: wrap;
            >li{
                margin: 0%;
                width: 90%;
                margin-left: -10%;
                margin-bottom: 8%;
                
            }
        }
    }

    .home_about_style{
        margin: 4%;
        >h1{
            margin-left: 4%;
            margin: 8%;
            text-align: center;
            font-weight: 700;
        }
        >div{
            text-align: center;
            box-shadow: 0 0 25px 0 rgba(24, 132, 240, 0.575);
            >img{
                width: 80%;
                margin: 2%
            }
            >div{
                padding: 4%;
                margin-left: 3%;
                margin-right: 3%; 
                font-size: 120%;
                font-weight: 400;
            }
        }
    }

    .home_hours_style{
        text-align: center;
        margin: 8%;
        >div:nth-child(1){
            font-size: 160%;
            font-weight: 600;
            display: block;
        }
        >div{
            font-size: 120%;
            display: block;
            >span{
                display: none;
            }
        }
    }

}